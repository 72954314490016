import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>
        Create {"\u00B7"} Run {"\u00B7"} Share <br></br> AI + UI Agents
      </Subtitle>
      {/* <SectionTitle>Rapidly build and run <br></br> AI + human pipelines</SectionTitle> */}
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>AI + UI</FeatureTitle>
          <FeatureText>
            Create agents that unify AI models and human UIs to solve complex
            problems and increase business productivity.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Collaborative Intelligence</FeatureTitle>
          <FeatureText>
            Automatically fine-tune AI models and LLMs for your use cases with
            collaborative learning from human feedback.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Flows</FeatureTitle>
          <FeatureText>
            Modularize AI models and pipelined UIs into specialized components
            and easily compose them for any small or big task.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Flow Store</FeatureTitle>
          <FeatureText>
            Share, discover and reuse flows and connectors from other developers
            to create customized AI + UI agents.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Flexible Modes</FeatureTitle>
          <FeatureText>
            Operate the agent in online mode as a real-time API or in offline
            mode as an app for human-in-the-loop processing or both!
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Low Code</FeatureTitle>
          <FeatureText>
            Use flow builder and composer tools to rapidly build and deploy AI +
            UI agents for web and mobile in minutes.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

// const SectionTitle = styled.h3`
//   color: ${props => props.theme.color.primary};
//   display: flex;
//   justify-content: center;
//   margin: 0 auto 40px;
//   text-align: center;
// `

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 740px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
